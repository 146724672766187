import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Expandable, ExpandableToggle, ExpandableToggleIcon, ExpandableBody, ExpandableContext } from '.';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { useContext } from 'react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CustomToggle = makeShortcode("CustomToggle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "expandable"
    }}>{`Expandable`}</h1>
    <p>{`The Expandable is a collection of utility components that work together to create expandable/togglable content.`}</p>
    <p>{`It doesn't style the expandable content, that's left up to you when you build the layout.`}</p>
    <p>{`Make sure `}<inlineCode parentName="p">{`Expandable`}</inlineCode>{` has exactly one instance of `}<inlineCode parentName="p">{`ExpandableToggle`}</inlineCode>{` and one instance of `}<inlineCode parentName="p">{`ExpandableBody`}</inlineCode></p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <h3 {...{
      "id": "expandable-1"
    }}>{`Expandable`}</h3>
    <Props of={Expandable} mdxType="Props" />
    <h3 {...{
      "id": "expandablebody"
    }}>{`ExpandableBody`}</h3>
    <Props of={ExpandableBody} mdxType="Props" />
    <p>{`For an example how to use `}<inlineCode parentName="p">{`toggleDisplay`}</inlineCode>{` on `}<inlineCode parentName="p">{`ExpandableBody`}</inlineCode>{` to disable automatic toggling of `}<inlineCode parentName="p">{`display: block`}</inlineCode>{` to `}<inlineCode parentName="p">{`display: none`}</inlineCode>{` when using animations, check out `}<a parentName="p" {...{
        "href": "/comoponents/Navigation"
      }}>{`Navigation`}</a></p>
    <h2 {...{
      "id": "use"
    }}>{`Use`}</h2>
    <Playground __position={2} __code={'<Expandable>\n  <ExpandableToggle>\n    <Heading as=\"h4\">Click to expand</Heading>\n  </ExpandableToggle>\n  <ExpandableBody>\n    <Paragraph>And you can read so much more. It\'s a miracle</Paragraph>\n  </ExpandableBody>\n</Expandable>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Expandable,
      ExpandableToggle,
      ExpandableToggleIcon,
      ExpandableBody,
      ExpandableContext,
      Heading,
      Paragraph,
      useContext,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Expandable mdxType="Expandable">
    <ExpandableToggle mdxType="ExpandableToggle">
      <Heading as='h4' mdxType="Heading">Click to expand</Heading>
    </ExpandableToggle>
    <ExpandableBody mdxType="ExpandableBody">
      <Paragraph mdxType="Paragraph">And you can read so much more. It's a miracle</Paragraph>
    </ExpandableBody>
  </Expandable>
    </Playground>
    <h2 {...{
      "id": "many-toggles-initial-state"
    }}>{`Many Toggles, initial state`}</h2>
    <p>{`Since context is held locally, there can be many Expandables on the same page. You should even be able to nest them.`}</p>
    <p>{`Set prop `}<inlineCode parentName="p">{`initExpanded={true}`}</inlineCode>{` to initialise the component in an extended state.`}</p>
    <Playground __position={3} __code={'<Expandable\n  onToggle={expanded => {\n    console.log(expanded ? \'expanded\' : \'closed\')\n  }}\n>\n  <ExpandableToggle>\n    <Heading as=\"h4\">Click to expand</Heading>\n  </ExpandableToggle>\n  <ExpandableBody>\n    <Paragraph>And you can read so much more. It\'s a miracle</Paragraph>\n  </ExpandableBody>\n</Expandable>\n<Expandable initExpanded={true}>\n  <ExpandableToggle>\n    <Heading as=\"h4\">Starting expanded</Heading>\n  </ExpandableToggle>\n  <ExpandableBody>\n    <Paragraph>And you can read so much more. It\'s a miracle</Paragraph>\n  </ExpandableBody>\n</Expandable>\n<Expandable>\n  <ExpandableToggle>\n    <Heading as=\"h4\">Click to expand</Heading>\n  </ExpandableToggle>\n  <ExpandableBody>\n    <Paragraph>And you can read so much more. It\'s a miracle</Paragraph>\n  </ExpandableBody>\n</Expandable>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Expandable,
      ExpandableToggle,
      ExpandableToggleIcon,
      ExpandableBody,
      ExpandableContext,
      Heading,
      Paragraph,
      useContext,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Expandable onToggle={expanded => {
        console.log(expanded ? 'expanded' : 'closed');
      }} mdxType="Expandable">
    <ExpandableToggle mdxType="ExpandableToggle">
      <Heading as='h4' mdxType="Heading">Click to expand</Heading>
    </ExpandableToggle>
    <ExpandableBody mdxType="ExpandableBody">
      <Paragraph mdxType="Paragraph">And you can read so much more. It's a miracle</Paragraph>
    </ExpandableBody>
  </Expandable>
      <Expandable initExpanded={true} mdxType="Expandable">
  <ExpandableToggle mdxType="ExpandableToggle">
    <Heading as="h4" mdxType="Heading">Starting expanded</Heading>
  </ExpandableToggle>
  <ExpandableBody mdxType="ExpandableBody">
    <Paragraph mdxType="Paragraph">And you can read so much more. It's a miracle</Paragraph>
  </ExpandableBody>
      </Expandable>
      <Expandable mdxType="Expandable">
  <ExpandableToggle mdxType="ExpandableToggle">
    <Heading as="h4" mdxType="Heading">Click to expand</Heading>
  </ExpandableToggle>
  <ExpandableBody mdxType="ExpandableBody">
    <Paragraph mdxType="Paragraph">And you can read so much more. It's a miracle</Paragraph>
  </ExpandableBody>
      </Expandable>
    </Playground>
    <h2 {...{
      "id": "indicate-expandedcollapsed-state"
    }}>{`Indicate expanded/collapsed state`}</h2>
    <p><inlineCode parentName="p">{`Expandable`}</inlineCode>{` exports ExpandableContext, use it to render any icon or other styles you need to indicate expanded/collapsed state.`}</p>
    <Playground __position={4} __code={'() => {\n  // import { Expandable, ExpandableToggle, ExpandableBody, ExpandableContext } from \'hoppin-design-system\'\n  // import { useContext } from \'react\'\n  const CustomToggle = () => {\n    const { isExpanded } = useContext(ExpandableContext)\n    return (\n      <Heading as=\"h4\" color={isExpanded ? \'primary.base\' : \'inherit\'}>\n        {isExpanded ? \'Click to close\' : \'Click to open\'}\n      </Heading>\n    )\n  }\n  return (\n    <React.Fragment>\n      <Expandable>\n        <ExpandableToggle>\n          <CustomToggle />\n        </ExpandableToggle>\n        <ExpandableBody>\n          <Paragraph>And you can read so much more. It\'s a miracle</Paragraph>\n        </ExpandableBody>\n      </Expandable>\n      <Expandable>\n        <ExpandableToggle>\n          <Heading as=\"h4\">\n            Add ExpandableToggleIcon to indicate toggle state{\' \'}\n            <ExpandableToggleIcon />\n          </Heading>\n        </ExpandableToggle>\n        <ExpandableBody>\n          <Paragraph>And you can read so much more. It\'s a miracle</Paragraph>\n        </ExpandableBody>\n      </Expandable>\n    </React.Fragment>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Expandable,
      ExpandableToggle,
      ExpandableToggleIcon,
      ExpandableBody,
      ExpandableContext,
      Heading,
      Paragraph,
      useContext,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        // import { Expandable, ExpandableToggle, ExpandableBody, ExpandableContext } from 'hoppin-design-system'
        // import { useContext } from 'react'
        const CustomToggle = () => {
          const {
            isExpanded
          } = useContext(ExpandableContext);
          return <Heading as="h4" color={isExpanded ? "primary.base" : "inherit"} mdxType="Heading">
          {isExpanded ? "Click to close" : "Click to open"}
        </Heading>;
        };

        return <React.Fragment>
        <Expandable mdxType="Expandable">
          <ExpandableToggle mdxType="ExpandableToggle">
            <CustomToggle mdxType="CustomToggle" />
          </ExpandableToggle>
          <ExpandableBody mdxType="ExpandableBody">
            <Paragraph mdxType="Paragraph">And you can read so much more. It's a miracle</Paragraph>
          </ExpandableBody>
        </Expandable>
        <Expandable mdxType="Expandable">
          <ExpandableToggle mdxType="ExpandableToggle">
            <Heading as="h4" mdxType="Heading">Add ExpandableToggleIcon to indicate toggle state <ExpandableToggleIcon mdxType="ExpandableToggleIcon" /></Heading>
          </ExpandableToggle>
          <ExpandableBody mdxType="ExpandableBody">
            <Paragraph mdxType="Paragraph">And you can read so much more. It's a miracle</Paragraph>
          </ExpandableBody>
        </Expandable>
      </React.Fragment>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      